.SectionOneVideo{
    /* border-radius: 20px; */
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.SectionOneVideo:nth-last-child() {
    width: 100%;
}
.OneVideoDescript{
    border-radius: 20px;
    position: relative;
    width: 50%;
    padding-bottom: 28.125%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.descriptionOneVideo {
    text-align:justify;
    height: 100%;
    width: calc(50% - 20px);
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.containerOneVideoDescript{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}
.OneVideo{
    border-radius: 20px;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Oneminiature{
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.TitleOneVideo{
    width: 100%;
    height: auto;
    text-align: left;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 10px 0;
}

.ytp-large-play-button{
    background-color: rgba(0,0,0,0.5) !important;
    position: relative !important;
}

@media (max-width: 768px) {  
    .TitleOneVideo {
        width: 100%;
        font-size: 22px;
    }
    .containerOneVideoDescript{
        flex-direction: column;
        gap: 10px;
    }
    .OneVideoDescript{
        width: 100%;
        padding-bottom: 56.25%;
    }
    .descriptionOneVideo {
        width: 100%;
        text-align: left;
        -webkit-line-clamp: 4; 
    }
}
@media (max-width: 400px) {  
    .TitleOneVideo {
        font-size: 20px;
    }
}

