.DashboardPages{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 230px);
    height: 100%;
    min-height: calc(100vh - 70px);
    gap: 80px;
    padding: 0 0 50px 0;
    background-color: var(--background-secondary);
    border-top: var(--border) 1px solid;
    border-left: var(--border) 1px solid;
    border-radius: 20px 0 0 0;
}
.titleDashboard{
    color: var(--text-primary);
    margin: 0;
    font-size: 28px;
    text-transform: capitalize;
    line-height: 28px;
}
.containerDashboardPage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 50px;
}
.containerDashboardPageTest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    height: 100%;
}
.containerDashboardPageTest p{
    margin: 0;
}
.menuLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    border-radius: 15px;
    padding: 20px;
    width: 300px;
}
.settings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    border-radius: 15px;
    padding: 20px;
    width: 50%;
}
.containerTitleSettings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
    width: 100%;
}
.titleSectionSettings{
    text-align: left;
    font-size: 24px;
    margin: 0;
    font-weight: 500;
}
.lineTitleSectionSettings{
    background-color: var(--accent);
    width: 100%;
    height: 1px;
}
.containerSettingsGeneral{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
}
.containerButtonActionContentPage{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    height: 50px;
}
.subtitleGeneral{
    margin: 0;
    font-weight: 500;
}

/* From Uiverse.io by satisshTechie */ 
.PB-range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: var(--text-primary);
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .PB-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--accent);
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  
  .PB-range-slider::-webkit-slider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease-in-out;
  }
  
  .PB-range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--accent);
    cursor: pointer;
  }
  
  .PB-range-slider-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 5px;
  }
  
  .PB-range-slidervalue {
    margin-bottom: 5px;
  }