.container-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100%);
}

.draggable-container {
    padding: 10px 5px;
    background-color: var(--background-primary);
    border: var(--border) 1px solid;
    border-radius: 10px;
    height: auto;
    cursor: default !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    width: 100%;
}

.iconDragAndDrop i {
    color: var(--text-primary);
    font-size: 16px;
}