.PricingPack{
    width: 280px;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.1);
    padding: 20px 30px;
    position: relative;
}
.titlePricingPack{
    text-align: left;
    font-size: 25px;
    font-weight: bold;
    margin: 0;
}
.mostPopular{
    color: #fff;
    background-color: #ff8500;
    border-radius: 50px;
    padding: 3px 6px;
    font-size: 12px;
    text-align: center;
    width: auto;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 10px;
}
.containerPrice{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
}
.pricePricingPack{
    text-align: center;
    font-size: 20px;
    margin: 0;
}
.pricePricingPack.reduc{
    font-size: 14px;
    text-decoration:line-through;
}
.containerItemsInPack{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: auto;
    width: 100%;
    gap: 8px;
}
.itemPack{
    text-align: left;
    font-size: 12px;
    margin: 10px 0;
}