.Article {
    height: auto;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}

.imgArticle {
    height: 140px;
    width: 140px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px;
}

.infoArticle {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.titleArticle {
    /* white-space: nowrap;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden; */
    margin: 16px 0 4px 0;
    max-width: 140px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}
.containerImageDefaultArticle {
    height: 140px;
    min-width: 140px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    max-width: 60px;
}

.containerImageDefaultArticle i {
    color: white;
    font-size: 1025%;
}

.priceArticle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 0;
}

@media (max-width: 500px) {  
    .Article {
        width: 120px;
    }
    .containerImageDefaultArticle {
        height: 120px;
        min-width: 120px;
    }
    .containerImageDefaultArticle i {
        font-size: 880%;
    }
    .imgArticle {
        height: 120px; 
        width: 120px; 
    }
    .titleArticle {
        max-width: 120px;
    }
}



