.titleProfile {
    font-size: clamp(35px, 8vw, 55px);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
}

.Logo{
    margin: 0px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.subtitleProfile{
    font-size: 20px;
    text-align: center;
    margin: 0;
    width: 80%;
}

.imgLogo{
    width: 400px;
    object-fit: cover;
    object-position: center;
    margin: 30px auto 10px auto;
    height: auto;
}
@media (max-width: 425px) {  
    .imgLogo{
        width: 300px;
    }
    .subtitleProfile {
        width: 90%;
        font-size: 18px;
    }
}

@media (max-width: 350px) {  
    .imgLogo{
        width: 250px;
    }
}