.Pricing {
    text-align: center;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.containerTitlePricing{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    width: 90%;
}
.titlePricing{
    text-align: left;
    width: 70%;
    font-weight: bold;
    margin: 30px auto 10px 0;
}
.subtitlePricing{
    font-size: 16px;
    text-align: left;
    width: 60%;
    margin: 0;
}
.containerButtonPricingDate{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 90%;
    height: auto;
}
.NavigationPricing{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin: 20px auto 0 auto;
}
.containerButtonNavigationPricing{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 190px;
    position: relative;
}
.containerButtonNavigationPricing.date{
    border: #ff8500 solid 2px;
    border-radius: 0.5em;
    box-shadow: 0 0.3em 0 #ff8500;
    transition: all 0.3s ease-in-out;
}
.buttonActiveLinePricing{
    height: 3px;
    width: 50%;
    bottom: 0px;
    background-color: #ff8500;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 50%;
}
.buttonActiveLinePricing.active{
    right: 0;
}
.ButtonMenuPricing{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out;
}
.ButtonMenuPricing.active {
    color: #ff8500;
}
.buttonPricingDate{
    font-size: 14;
    cursor: pointer;
    color: #000;
    font-weight: 600;
    height: 40px;
    width: 50%;
    background-color: transparent;
    border: none;
    transition: all 0.4s ease-in-out;
    z-index: 1;
}
.buttonPricingDate.active {
    color: #fff;
}
.buttonActiveBlockPricing{
    background-color: #ff8500;
    border-radius: 0.4em;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 50%;
    height: 40px;
    width: 50%;
    z-index: 0;
}
.buttonActiveBlockPricing.active{
    right: 0;
}
.containerPricingPack{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    gap: 3%;
    margin: 40px auto 30px auto;
}