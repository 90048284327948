.MusicProfil {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 100%;
    position: relative;
    text-decoration: none;
    border-radius: 10px;
    margin: 50px auto 30px auto ;
}

.imgProfil{
    position: absolute;
    left: 24px;
    top: -34px;
    border-radius:10px ;
    object-fit: cover;
    object-position:center;
    height: 160px;
    width: 160px;
}
.containerInfoPerso{
    position: absolute;
    right: 20px;
    top: 15px;
    text-align: left;
    width: calc(100% - 224px);
}
.titleProfil{
    white-space: nowrap;
    text-transform: capitalize;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    font-weight: 600 ;
}
.singerProfil{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
    margin: 5px 0 0px 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: 400 ;
}
.linkPlateformMusic{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    width: calc(100% - 48px);
    position: absolute;
    bottom: 20px;
    height: 50px;
    border-radius: 50px;

}
.linkImgPlateformMusic{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
}
.linkImg i{
    font-size: 24px;
}
.moreButton{
    border-radius: 50px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-transform: capitalize;
}
@media (max-width: 768px) {  
    .imgProfil {
        height: 140px;
        width: 140px;
    }
    .containerInfoPerso {
        width: calc(100% - 204px);
    }
    .linkImgPlateform img {
        height: 22px;
        width: 22px;
    }
    .MusicProfil {
        height: 200px;
    }
    .moreButton {
        font-size:18px ;
        line-height: 18px;
    }
}
@media (max-width: 425px) {  
    .linkImgPlateform img {
        height: 22px;
        width: 22px;
    }
    .linkImgPlateform p{
        font-size: 14px;
    }
    .morePlateform {
        font-size: 14px;
    }
    .linkImgPlateform{
        gap: 5px;
    }
    .imgProfil {
        height: 120px;
        width: 120px;
        top: -14px;
    }
    .containerInfoPerso {
        width: calc(100% - 184px);
    }
    .LinkedinProfil {
        margin: 35px auto 30px auto;
    }
    .moreButton {
        font-size:16px ;
        line-height: 16px;
    }
}

@media (max-width: 350px) {  
    .linkImgPlateform img {
        height: 20px;
        width: 20px;
    }
    .MusicProfil {
        height: 160px;
    }
    .linkPlateformMusic {
        width: calc(100% - 20px);
        padding: 0 10px;
        height: 40px;
        left: 10px;
    }
    
    .linkImgPlateform{
        gap: 3px;
    }
    .imgProfil {
        left: 10px;
        height: 100px;
        width: 100px;
    }
    .containerInfoPerso {
        right: 10px;
        width: calc(100% - 135px);
    }
    .moreButton {
        font-size:14px ;
        line-height: 14px;
    }
}

.textLRIcon{
    width: 50% !important;
}