.Home {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-secondary);
}
.sectionHome1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: auto;
    width: 80%;
    position: relative;
}
.containerTitleHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%;
    gap: 15px;
}
.titleHome{
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    margin: 0;
}
.subtitleHome{
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    margin: 0;
}
.containerImagesHome {
    height: 550px;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    object-position: center;
}
.containerImagesHome p {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}
.buttonDashboard:hover {
    background-color: #fba141;
}
.sectionHome2 {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    position: relative;
}
.containerDepartment {
    align-items: flex-start;
    background: #ffffff;
    background: var(--secondaire-violet, #ffffff);
    /*background-image: linear-gradient(to left bottom, #ff8500, #ff9123, #ff9d39, #ffa74c, #ffb25f);*/
    border-radius: 16px;
    display: table-cell;
    -webkit-filter: drop-shadow(0 4px 34px rgba(0, 0, 0, .08));
    filter: drop-shadow(0 4px 34px rgba(0, 0, 0, .08));
    flex-direction: column;
    width: calc(250px);
    height: calc(600px);
    justify-content: space-between;
    padding: 24px;
}
.titleDepartment {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin: 0;
}
.subtitleDepartment {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 5px 0 15px 0;
    color:#ff8500;
}
.containerListDartDepartment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}
.dartDepartment {
    font-size: 12px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
    justify-content: flex-start;
    gap: 5px;
    font-weight: 500;
}
.dartDepartment span {
    min-height: 5px;
    position: relative;
    top: 7px;
    min-width: 5px;
    border-radius: 50%;
    background-color: #ff8500;
}
.container-presentation-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 80px 10% 80px 10%;
    width: 100%;
    justify-content: space-between;
    height: auto;
    background: #ff8500;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
}

.presentation-img {
    width: 368px;
    height: 223px;
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    -webkit-flex-grow: 0; 
    -ms-flex-positive: 0;
}

.presentation-title {
    font-size: 25px;
    font-weight: bold;
    flex-grow: 0;
    margin: 20px 0 10px 0;
}

.presentation-text {
    width: 90%;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    text-align: left;
}

.presentation-ligne {
    height: 3px;
    width: 80%;
    background-color: #f4f5ff;
    margin-left: 10%;
    opacity: 0.8;
    border-radius: 5px;
}

.presentation-top-right {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    display: -webkit-flex;
    gap: 15px;
    -webkit-align-items: flex-start;
    -webkit-justify-content: space-between;
}

.presentation-top-left {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: column; 
    -webkit-align-items: flex-start;
    -webkit-justify-content: center; 
}

.presentation-top-left a {
    text-decoration: none;
}

.presentation-top-right .presentation-text {
    width: 90%;
}

.div-top-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-flex-direction: column; 
    -webkit-align-items: center;
}

@media (max-width: 1200px) {
    .sectionHome1 {
        width: 90%;
        gap: 30px;
    }   
    .containerImagesHome {
        height: 500px;
    }
    .container-presentation-top {
        padding: 80px 5% 80px 5%;
        width: calc(100% - 10%);
    }
}
@media (max-width: 1000px) {
    .titleHome {
        font-size: 25px;
    }
    .presentation-top-right{
        width: 50%;
    }
}
@media (max-width: 850px) {
    .container-presentation-top{
        flex-direction: column;
        gap: 30px;
        padding: 50px 5% 50px 5%;
    }
    .presentation-top-left {
        width: 80%;
        align-items: center;
        -webkit-align-items: center;
    }
    .presentation-text {
        text-align: center;
    }
    .presentation-top-right{
        width: 80%;
    }
}
@media (max-width: 768px) {
    .sectionHome1 {
        flex-direction: column-reverse;
        gap: 0px;
    }
    .containerTitleHome {
        width: 80%;
    }
    .containerImagesHome {
        min-width: auto;
    }
    .titleHome, .subtitleHome {
        text-align: center;
    }
    .sectionHome2 {
        margin-top: 50px;
    }
    .container-presentation-top {
        margin-top: 50px;
    }
}

@media (max-width: 550px) {
    .sectionHome2 {
        width: 90%;
    }
    .containerDepartment {
        height: auto;
    }
    .presentation-top-left {
        width: 90%;
    }
    .presentation-top-right{
        width: 90%;
    }
}

@media (max-width: 500px) {
    .containerImagesHome {
        height: 400px;
    }
    .containerTitleHome {
        width: 90%;
    }
}

@media (max-width: 375px) {
    .containerImagesHome {
        height: 350px;
    }
    .presentation-img {
        width: 100%;
        height: auto;
    }
    .presentation-top-left {
        width: 100%;
    }
    .presentation-top-right{
        width: 100%;
    }
}
