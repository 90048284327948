.container-question {
    align-items: flex-start;
    align-items: center;
    background-color: 	hsla(31, 100%, 50%, .6);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    margin: 10px 0;
    overflow: hidden;
    align-items: flex-start;
    padding: 20px;
    transition: height .3s ease-in-out, background .3s ease-in-out, margin .3s ease-in-out;
    width: calc(100% - 40px);
}
.iconButtonQuestion.active i{
    transform: rotate(0deg);
}
.question{
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    text-decoration: none;
    width: 100%;
    gap: 10px;
}
.title-question{
    color: #252c32;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin: 0 !important;
}
.iconButtonQuestion{
    display: flex;
    width: 16px;
    position: relative;
    align-items: center;
    justify-content: center;
}
.iconButtonQuestion i{
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.answer-question{
    color: rgba(0, 0, 0, .6);
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 20px;
    width: 90%;
    text-align: justify;
}
