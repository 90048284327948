.InputField{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: auto;
}
.InputFieldColor{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: auto;
}
.SectionInputFieldColor{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
.InputFieldTitle{
    font-size: 14px;
    margin: 0;
}
.InputFieldInput[type="text"]{
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid #ff8500;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    color: #fff;
}
.InputFieldInput::placeholder{
    color: #ff8500;
}
.InputFieldInput:focus {
    border-color: #ff8500;
}
/* Style de base pour l'input de type color */
.InputFieldInput[type="color"] {
    border: none;
    border-radius: 5px;
    outline: none; 
}

/* Style pour l'input de type file */
.InputFieldInput[type="file"] {
    
}

/* Style pour le bouton "Parcourir" à côté de l'input de type file */
.InputFieldInput[type="file"]::-webkit-file-upload-button {
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid #ff8500;
    color: #ff8500;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

/* Style pour le bouton "Parcourir" à côté de l'input de type file lorsqu'il est en hover */
#fileInputLabel:hover {
    background-color: #ff8500;
    color: #fff;
}
#fileInputLabel{
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid #ff8500;
    color: #ff8500;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.errorMessageInputField{
    font-size: 10px;
    color: red;
    text-align: left;
    margin: 0;
}
.InputFieldColor > input[type = "text"]{
    background-color: transparent;
    outline: none;
    text-transform: uppercase;
    margin: 0;
    width: calc(100% - 20px);
    padding: 5px 10px;
    height: auto;
    border: 2px solid #ff8500;
    border-radius: 10px;
}
.inputColor {
    border: 2px solid #ff8500;
    border-radius: 50%;
    min-width: 30px;
    max-width: 30px;
    padding: 0px;
    overflow: hidden;
    height: 30px;
    cursor: pointer;
    outline: none;
}

.inputColor::-webkit-color-swatch-wrapper {
    padding: 0px;
}

.inputColor::-webkit-color-swatch {
    border: none;
    padding: 2px;
}

.InputFieldText{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
}
.InputFieldTextTitle{
    font-size: 16px;
    width: 100%;
    text-align: left;
    color: var(--text-primary);
    text-transform: capitalize;
    margin: 0;
    font-weight: 600;
}
.InputFieldTextInput {
    border: #ff8500 2px solid;
    border-radius: 10px;
    padding: 10px ;
    width: calc(100% - 24px);
}

.InputFieldTextInput:focus {
    outline: none;
}

.InputFieldText.focused .InputFieldTextInput {
    border-color: #ff8500;
}

.InputPhoneNumberContainer > input {
    background-color: var(--card-background) !important;
    border-color: var(--text-secondary) !important;
    caret-color: var(--text-primary) !important;
    color: var(--text-primary) !important;
}
.InputPhoneNumberContainer > input::placeholder {
    color: var(--text-secondary) !important;
    
}