.NavigationHome{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 0px 20px;
    height: 70px;
    width: 100%;
    position: relative;
    background-color: var(--background-primary);
}
.logo {
    height: 40px;
    width: auto;
    object-position: center;
    object-fit: contain;
}
.containerNavigationButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    gap: 20px;
    height: auto;
    cursor: pointer;
}
.containerNavigationButtonMobile {
    display: none;
}
.containerHamburgermenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    gap: 5px;
    cursor: pointer;
    border: none;
    background-color: transparent;
}
.linehamburger {
    height: 4px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--text-primary);
    transition: all 0.3s ease;
}

.linehamburger.open:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.linehamburger.open:nth-child(2) {
    opacity: 0;
}

.linehamburger.open:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}
.containerHamburgerMenuMobile {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    background-color: black;
    height: calc(100vh - 60px);
    top: 60px;
    width: 170px;
    padding: 20px;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}
.linkNavigation {
    color: var(--text-primary);
    text-decoration-line: none;
    text-align: center;
}
.linkNavigation.signup {
    background-color: var(--accent);
    padding: 5px 10px;
    border-radius: 50px;
    border: var(--text-primary) solid 1px;
    z-index: 1;
    position: relative;
    box-shadow: 0 0.2em 0 var(--text-primary);
    transition: all 0.3s ease-in-out;
}
.linkNavigation.signup:hover {
    background-color: var(--hover);
}
.linkNavigationProfil {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    width: auto;
    height: auto;
    text-decoration-line: none;
}
.linkNavigationProfil.signup {
    background-color: var(--accent);
    padding: 5px 10px;
    border-radius: 50px;
    border: var(--text-primary) solid 1px;
    z-index: 1;
    position: relative;
    box-shadow: 0 0.2em 0 var(--text-primary);
    transition: all 0.3s ease-in-out;
}
.imgProfilNavigation{
    border-radius: 50px;
    height: 30px;
    width: 30px;
    object-fit: cover;
    object-position: center;
    background-color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}
.imgProfilNavigation > i {
    color: #ff8500;
    font-size: 30px;
    margin: 10px 0 0 0;
}



@media (max-width: 768px) {
    .containerNavigationButton {
        display: none;
    }
    .containerNavigationButtonMobile {
        display: flex;
        margin-right: 5%;
    }
    .NavigationHome {
        justify-content: space-between;
    }
    .containerLogoNavigationHome {
        margin-left: 5%;
    }
}
