.LatestRelease {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    height: 90px;
    width: 100%;
    position: relative;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    margin: 25px auto 10px auto ;
}

.musicLR{
    position: absolute;
    border-radius:10px ;
    object-fit: cover;
    object-position:center;
    height: 60px;
    width: 60px;
    top: -15px;
    left: 10px;
}
.containerInfoMusicLR{
    position: absolute;
    text-align: left;
    top: 8px;
    right: 10px;
    width: calc(100% - 90px);
}
.titleLR{
    font-size: 14px;
    text-transform: capitalize;
    white-space: nowrap;
    text-transform: capitalize;
    text-align: left;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
}
.singerLR{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    font-size: 10px;
    margin: 2px 0 10px 0 !important;
}
.linkPlateform{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    left: 20px;
    position: absolute;
    border-radius: 50px;
    height: 25px;
    bottom: 8px;
}
.linkImgPlateform{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 30%;
    cursor: pointer;
    text-decoration: none;
}
.linkImgPlateform img{
    height: 15px;
    width: 15px;
    object-fit: cover;
    object-position: center;
}
.linkImgPlateform p {
    font-size: 12px;
}
.lineLR{
    height: 18px;
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 50px;
}
.morePlateform{
    border-radius: 50px;
    text-decoration: none;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    padding: 5px 10px;
    /* border: 1px solid #f1f1f1; */
    border: none;
    background-color: transparent;
    text-transform: capitalize;
}
.containerMorePlateform{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}

@media (max-width: 425px) {  
    .linkImgPlateform img {
        height: 22px;
        width: 22px;
    }
    .linkImgPlateform p{
        font-size: 14px;
    }
    .morePlateform {
        font-size: 14px;
    }
    .titleLR {
        font-size: 16px;
    }
    .singerLR {
        font-size: 12px;
        margin: 5px 0 10px 0 !important;
    }
}

@media (max-width: 350px) {  
    .linkImgPlateform img {
        height: 20px;
        width: 20px;
    }
    .LatestRelease {
        height: 160px;
    }
    .linkPlateform {
        width: calc(100% - 20px);
        left: 10px;
    }
    .musicLR {
        left: 10px;
        height: 100px;
        width: 100px;
    }
    .containerInfoMusicLR {
        right: 10px;
        width: calc(100% - 140px);
    }
}