.jsAnchorLink{
    white-space: nowrap;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.5s ease-in-out;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 32px;
}
@media (max-width: 350px) {  
    .jsAnchorLink {
        font-size: 18px !important;
        line-height: 30px;
    }
}