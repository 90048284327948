.qrCodeButton {
  border: none;
  border-radius: 35px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1;
}
.qrCodeAddContact svg {
  border-radius: 55px;
}
.qrCodeButton i {
  font-size: 40px;
}

.qrCodeModal {
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.qrCodeOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.sectionQRCode {
  border-radius: 10px;
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

}
.containerQRCode{
  display: flex;
  border-radius: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFF;
  overflow: hidden;
}

.containerProfilAddContact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  gap: 20px;
  width: 100%;
}
.containerProfilAddContact img {
  object-fit: cover;
  height: 80px;
  width: 80px;
  border-radius: 20px;
  object-position: center;
}

.profilAddContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 140px);
  gap: 10px;
}
.profilAddContact p {
  margin: 0;
  width: 100%;
  text-align: left;
}
.addContactButton {
  width: 100%;
  background-color: #FFF;
  border: none;
  border-radius: 30px;
  color: #ff8500;
  color: #000;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}
.qrcode i{
  font-size: 24px;
}
.qrcode {
  margin-top: 2px;
}
.qrCode {
  border-radius: 10px;
}