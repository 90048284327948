.ReseauxSociaux{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px 24px;
    width: 90%;
    height: auto;
    margin: 20px auto;
    position: relative;
    flex-wrap: wrap;
}
@media (max-width: 425px) {  
    .ReseauxSociaux{
        gap: 5px 20px;
    }
}