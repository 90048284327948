.Article {
    height: auto;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.containerImageDefaultArticle {
    height: 60px;
    min-width: 60px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    max-width: 60px;
}

.containerImageDefaultArticle i {
    color: white;
    font-size: 430%;
}
.imgArticle {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 5px;
}

.infoArticle {
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.titleArticle {
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    text-transform: capitalize;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 0px 0;
    max-width: 60px;
}

.priceArticle {
    font-size: 10px;
    text-align: left;
    margin: 0;
}

