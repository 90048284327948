@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
}

/* Thème sombre (défaut pour hobs space) */
:root[data-theme="dark"] {
  --background-primary:   #13183B;  /* Bleu nuit doux, légèrement éclairci */
  --background-secondary: #1E2750;  /* Bleu marine profond mais doux */
  --card-background:      #2D3765;  /* Bleu-violet foncé translucide */
  --text-primary:         #ECEEFF;  /* Blanc légèrement bleuté très clair */
  --text-secondary:       #B0B8D6;  /* Gris-violet clair */
  --accent:               #FF945E;  /* Orange pastel doux moderne */
  --hover:                #A478FF;  /* Violet vibrant et lumineux */
  --border:               #3A4670;  /* Gris-bleu sombre doux */
}

/* Thème clair (créé pour respecter la DA de hobs space) */
:root[data-theme="light"] {
  --background-primary:   #FFFFFF;  /* Fond blanc épuré */
  --background-secondary: #F0F2F5;  /* Gris très clair pour le fond secondaire */
  --card-background:      #E4E7EB;  /* Teinte douce pour les cartes */
  --text-primary:         #13183B;  /* Texte foncé pour un bon contraste */
  --text-secondary:       #1E2750;
  --accent:               #FF945E;  /* Même accent */
  --hover:                #A478FF;
  --border:               #D1D5DB;  /* Bordure plus claire */
}

/* Si aucune donnée n'est définie, on par défaut sur le thème sombre */
:root:not([data-theme]) {
  --background-primary:   #13183B;
  --background-secondary: #1E2750;
  --card-background:      #2D3765;
  --text-primary:         #ECEEFF;
  --text-secondary:       #B0B8D6;
  --accent:               #FF945E;
  --hover:                #A478FF;
  --border:               #3A4670;
}

@theme inline {
  --color-background: var(--background-primary);
  --color-foreground: var(--text-primary);
  --font-sans: var(--font-sans);
  --font-mono: var(--font-mono);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-secondary);
  color: var(--text-primary);
  font-family: var(--font-sans);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: var(--text-primary) !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
