.LinkTikTok{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto; 
    gap: 5px;
    padding: 0px;
    width: auto;
    border-radius: 10px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}
.LinkTikTokThumbnail {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
.play{
    border-radius: 50px;
    text-decoration: none;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.play i{
    font-size: 22px;
}