.Partners {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}
.containerTitlePartners{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
}
.titlePartners{
    text-align: center;
    width: 70%;
    font-weight: bold;
}