.containerHome{
  border-radius: 20px;
  height: auto;
  margin-top: 50px;
  padding: 70px;
  width: 35%;
  min-width: 500px;
  z-index: 1;
}
.backgroundHome{
  width: 100%;
  height: 100%;
  filter: blur(100px);
  position: fixed;
  object-fit: cover;
  object-position: top;
  z-index: 0;
  display: none;
}
.lineSeparation{
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 10px auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.containerSpinner{
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.spinner {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
.spinner circle {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }
 

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 300px) and (max-width: 650px) {  
  .containerHome{
    margin-top: 0px;
    padding: 50px 0px;
    width: 85%;
    min-width: 85%;
  }
}

.titleOverflow {
  width: 490px;
}
.titleOverflow2 {
  width: 600px;
}
@media (max-width: 1450px) {
  .titleOverflow {
      width: 440px;
  }
}
@media (max-width: 1400px) {
  .titleOverflow {
      width: 380px;
  }
}
@media (max-width: 1350px) {
  .titleOverflow {
      width: 350px;
  }
}
@media (max-width: 1300px) {
  .titleOverflow {
      width: 300px;
  }
  .titleOverflow2 {
    width: 500px;
  }
}
@media (max-width: 1250px) {
  .titleOverflow {
      width: 250px;
  }
}
@media (max-width: 1200px) {
  .titleOverflow {
      width: 220px;
  }
  .titleOverflow2 {
    width: 400px;
  }
}
@media (max-width: 1100px) {
  .titleOverflow2 {
      width: 300px;
  }
}
@media (max-width: 1175px) {
  .titleOverflow {
      width: 195px;
  }
}
@media (max-width: 1150px) {
  .titleOverflow {
      width: 175px;
  }
}
@media (max-width: 1125px) {
  .titleOverflow {
      width: 150px;
  }
}