.ButtonSocialNetwork{
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.ButtonSocialNetwork p {
    margin: 0;
    color: #fff;
}

.linkOverflow {
    width: 100%;
}

@media (max-width: 1300px) {
    .linkOverflow {
        width: 400px;
    }
}

@media (max-width: 1250px) {
    .linkOverflow {
        width: 350px;
    }
}

@media (max-width: 1200px) {
    .linkOverflow {
        width: 300px;
    }
}
