.ImageProfile {
    height: auto;
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    margin: 0 auto;
    /*box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.5);*/
}
.vertical {
    height: 400px; 
    width: 300px; 
    position: relative;
}  
.horizontal {
    height: 300px; /* Hauteur calculée pour respecter le format 16:9 (9 / 16 * 400) */
    width: 450px; /* Largeur fixe, ajustez selon vos besoins */
    position: relative;
}  
.containerCover img {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    object-position: center;
    z-index: 1;
}
@media screen and (min-width: 460px) and (max-width: 650px) {  
    .horizontal {
      height: 280px;
      width: 400px;
      position: relative;
    }
}
@media screen and (min-width: 420px) and (max-width: 460px) {  
    .horizontal {
      height: 240px;
      width: 350px;
      position: relative;
    }
}
@media screen and (min-width: 350px) and (max-width: 420px) {  
    .horizontal {
      height: 200px;
      width: 300px;
      position: relative;
    }
    .vertical {
        height: 350px;
        width: 250px;
    }
}

@media screen and (min-width: 200px) and (max-width: 350px) {  
    .containerCover.horizontal {
      height: 180px;
      width: 270px;
      position: relative;
    }
    .containerCover.vertical {
        height: 350px;
        width: 250px;
    }
}