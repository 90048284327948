.sectionGetStarted{
    width: 80%;
    background-color: #fff;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 20px 0;
}
.containerTextGetStarted{
    width: 60%;
    margin-left: 5%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}
.titleGetStarted{
    font-size: 22px;
    font-weight: bold;
    color: #000;
    width: 100%;
    text-align: left;
    margin: 0;
}
.subtitleGetStarted{
    font-size: 16px;
    color: #000;
    width: 100%;
    text-align: left;
    margin: 0;
}
.sectionGetStarted button{
    margin: auto 5% auto 0;
}

@media (max-width: 850px) {
    .sectionGetStarted {
        flex-direction: column;
    }
    .containerTextGetStarted {
        width: 90%;
        margin-left: 0;
        align-items: center;
    }
    .titleGetStarted, .subtitleGetStarted {
        text-align: center;
    }

}