.Notify-container {
    position: fixed;
    top: 30px;
    width: auto;
    height: auto;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 10px 20px 10px 10px;
    z-index: 1001;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
.Notify-container p {
    margin: 0;
    color: #fff;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}
.Notify-container button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 15px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.Notify-container > button > i{
    font-size: 16px;
}
.Notify-progress {
    position: absolute;
    bottom: 2px;
    left: 0;
    height: 2px;
    background: white;
    transition: width 0.05s linear;
}