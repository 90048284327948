.LinkPodcast{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto; 
    gap: 5px;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}
.music{
    height: 70px;
    min-width: 70px;
    max-width: 70px;
    object-fit: cover;
    border-radius: 10px;
    object-position: center;
}
.containerInfoMusic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 0 0 10px;
    position: relative;
    height: auto;
    width: calc(100% - 70px) !important;
}
.containerInfoMusic > p{
    margin: 0;
}
.titlePodcast{
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    text-align:left;
    line-height: 14px;
    height: 100%;
    margin: 0 0 2px 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.singer{
    text-transform: capitalize;
    font-size: 10px;
    margin: 0px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
}
.play-pause-button-modal {
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.75rem;
    left: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
}
.containerButtonLinkPodacast {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;
    margin-top: 6px;
}

.containerImageDefaultMusic {
    height: 70px !important;
    min-width: 70px;
    max-width: 70px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.containerImageDefaultMusic i {
    color: white;
    font-size: 515%;
}
.searchBarWrapper {
    position: relative;
    width: 100%;
}

.searchIcon {
    position: absolute;
    right: 10px;
    top: 45px;
    transform: translateY(-50%);
    color: var(--text-primary);
    font-size: 1.2rem;
    cursor: pointer;
}
.resultsContainer {
    width: 100%;
    margin-top: 1rem;
    max-height: 420px;
    overflow-y: scroll;
}
.resultsContainer::-webkit-scrollbar {
    display: none;
}
.resultsContainer {
    scrollbar-width: none;
}

.resultItem {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    height: 89px;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid var(--border);
    transition: all 0.2s ease;
    cursor: pointer;
    border-radius: 10px;
}
.resultItem.hovered {
    background-color: var(--card-background);
    border-color: var(--border);
}
.platformIcons {
    display: flex;
    gap: 5px;
}
.platformIcon {
    min-width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}
.songImage {
    min-width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.titleSingerSong {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.titleSingerSong > strong {
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.platformSelection {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.platformButton {
    border: 2px solid transparent;
    background: none;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;
    transition: border-color 0.2s ease;
}

.platformButton.selected {
    background: linear-gradient(var(--text-primary), var(--text-primary)) padding-box, linear-gradient(270deg, #17e391, #58e5df, #60e5e8) border-box;
    border: 1px solid transparent;
}

.truncate-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
