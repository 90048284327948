/* .LinksPodcast > div > div > div > div {
    width: 140px;
    height: auto;
} */
.LinksPodcast > div > a {
    width: 100%;
}
.LinksPodcast > .Slider > div > div > .swiper-slide {
    margin-right: 8px;
}
.LinksPodcast > .Slider > div > div > .swiper-slide > div {
    width: 240px !important;
}