.Menu{
    width: calc(100% - 30px);
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 15px auto;
    position: relative;
}
.Menu::-webkit-scrollbar {
    display: none !important;
}
.Menu::-moz-scrollbar {
    display: none !important;
}

@media screen and (min-width: 300px) and (max-width: 500px) {  
    .Menu{
        margin: 20px auto;
    }
}
