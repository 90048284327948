/* MobilePreview.css */

.mobile-preview {
    border-radius: 38px;
    box-shadow:inset 0 0 2px 2px #c8cacb,inset 0 0 0 7px #e2e3e4;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  
  .mobile-stripe {
    width: 100%;
    position: absolute;
    z-index: 0;
    height: 3px;
    background-color: gray;
  }
  .mobile-stripe.top {
    top: 15%;
  }
  .mobile-stripe.bottom {
    bottom: 15%;
  }
  .mobile-style {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    box-sizing: border-box;
    border-radius: 36px;
    border: 10px solid #222;
    position: relative;
    overflow: hidden;
  }
  .mobile-content-image {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  }
  .mobile-content {
    width:100%;
    height:100%;
    padding: 10px;
    padding-bottom: 30px;
    box-sizing: border-box;
    position: relative;
  }
  
  .mobile-content-image::-webkit-scrollbar {
    display: none;
  }
  
  .mobile-content-image::-webkit-scrollbar-thumb {
    display: none;
  }
  