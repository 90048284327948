.inputPhoneMe > div {
    width: min-content !important;
}
.inputPhoneMe > div > div > input {
    overflow: hidden;
    border-color: var(--text-secondary) !important;
}
.inputPhoneMe > div > div > div {
    background-color: var(--text-secondary) !important;
    border-radius: 5px 0 0 5px !important;

}