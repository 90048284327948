.buttonDisplayEmailPhone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 0 auto;
    gap: 8px;
    background-color: transparent;
    border: none;
}

.buttonDisplayEmailPhone > div > i {
    margin-top: 0px;
    font-size: 24px;
}
@media (max-width: 425px) {  
    .buttonDisplayEmailPhone > div > i {
        font-size: 20px;
    }
}