.sectionQrCodeGenerator {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    border-radius: 10px;
    padding: 20px 0;
    gap: 20px;
    width: 100%;
}
.customQrCode {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    padding: 10px;
    gap: 20px;
    width: calc(100% - 410px);
}
.previewQrCode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    padding: 30px 10px;
    gap: 20px;
    width: 355px;
    position: -webkit-sticky; /* Pour les anciens navigateurs */
    position: sticky;
    top: 0;
    overflow: hidden;
}
