.containerEditComponent {
    width: 550px;
    height: auto;
    max-height: 80vh;
    padding: 20px;
    border-radius: 10px;
    background-color: #f4f5ff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto; /* Permet le défilement tout en masquant les barres de défilement */
    justify-content: flex-start;
    gap: 15px;
}
.buttonEditUnderComponent svg {
    height: 16px;
    width: 16px;
}
/* Masquer les barres de défilement pour les navigateurs WebKit */
.containerEditComponent::-webkit-scrollbar {
    display: none;
}

.titleEditComponent{
    margin: 0;
    text-transform: capitalize;
}
.containerEditComponent > div > input {
    width: calc(100% - 20px);
    padding: 5px 10px;
    height: auto;
    border: 1px solid var(--accent);
    border-radius: 10px;
}
.containerEditComponent > div:last-child {
    width: 100%;
    margin-top: 0;
}
.errorEditComponent{
    color: red;
    font-size: 10px;
    margin: 0;
    width: 100%;
    text-align: left;
}
.ImgEditComponent{
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--card-background);
    border: var(--text-secondary) 1px solid;
    max-width: 40px;
}
.ImgEditComponent > i {
    font-size: 45px;
    color: var(--accent);
}
.containerEditImgComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
}

.containerEditComponent .containerDisplayEmail {
    width: 100%;
    justify-content: space-between;
}
.containerEditComponent .buttonAddNewBlock{
    margin: 0;
}
.containerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
}

.containerColumn > div > input {
    width: calc(100% - 20px);
    padding: 5px 10px;
    height: auto;
    border: 1px solid var(--accent);
    border-radius: 10px;
}