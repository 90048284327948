.Link {
    height: 70px; 
    margin: 0px 0; 
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.containerImageDefault {
    height: 70px;
    min-width: 70px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.containerImageDefault i {
    color: white;
    font-size: 515%;
}
.imgTicket {
    height: 70px; 
    width: 70px; 
    object-fit: cover;
    object-position: center;
}

.infoTicket {
    width: 100%;
    height: 100%;
    padding:0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.titleTicket {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: left;
    margin: 0;
}

.priceTicket {
    font-size: 12px;
    text-align: left;
    margin: 0;
}