.draggable-link {
    padding: 0px 10px 0px 0px;
    border-radius: 10px;
    cursor: default !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.draggable-container:active {
    cursor: grabbing;
}
.buttonHeaderPreviewHobsSpace.grip:active {
    cursor: grabbing;
}
.buttonHeaderPreviewHobsSpace.grip {
    cursor: grab;
}
.gripDot.dragAndDrop {
    flex-direction: row;
    gap: 2px;
}

.iconDragAndDrop {
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerButtonDragAndDrop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

}
.buttonDragAndDrop {
    border: none;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonDragAndDrop i {
    font-size: 18px;
}
.textDragAndDrop{
    width: 100%;
    text-align: left;
}
.containerEditComponent {
    width: 550px;
    height: auto;
    max-height: 80vh;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--background-secondary);
    border: var(--border) 1px solid;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto; /* Permet le défilement tout en masquant les barres de défilement */
    justify-content: flex-start;
    gap: 15px;
}