.File {
    height: 70px; 
    margin: 0px 0; 
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
