.play-pause-podcast {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.playPodcast, .pausePodcast{
    border-radius: 40px;
    text-decoration: none;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playPodcast i, .pausePodcast i{
    font-size: 10px;
}