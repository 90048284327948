.IndividualVideo > div > div > div > div {
    width: 408px;
    height: auto;
}
.IndividualVideo > div > div > div > div > div {
    margin-top: 0px !important;
}
@media (max-width: 525px) {  
    .IndividualVideo > div > div > div > div {
        width: 380px;
    }
}
@media (max-width: 425px) {  
    .IndividualVideo > div > div > div > div {
        width: 320px;
    }
}
@media (max-width: 375px) {  
    .IndividualVideo > div > div > div > div {
        width: 300px;
    }
}
@media (max-width: 350px) {  
    .IndividualVideo > div > div > div > div {
        width: 280px;
    }
}
