.SectionHobsSpace {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
    max-width: 1300px;
    gap: 30px;
}
/*le sticky marche car il n'y a pas d'overflow dans un parent*/
.ContainerPreviewHobsSpace {
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    max-width: 400px;
    padding: 10px 0px 20px 0px;
    justify-content: flex-start;
    position: -webkit-sticky; /* Pour les anciens navigateurs */
    position: sticky;
    top: 0;
     /* Ajustez cette valeur selon vos besoins */
}
.containerHeaderPreviewHobsSpace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;
    height: 30px;
    padding-bottom: 10px;
}

.titleHeaderPreviewHobsSpace {
    font-weight: bold;
    font-size: 14px;
}

.buttonHeaderPreviewHobsSpace {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1px;
    height: 20px;
    padding: 0;
    width: 25px;
    cursor: pointer;
}
.gripDot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
}

.dot{
    background-color: rgba(0,0,0,0.4);
    padding: 2px;
    border-radius: 50%;
    scale: 0.6;
}

.lineHobsSpace {
    background-color: var(--text-secondary);
    width: 100%;
    height: 2px;
    margin-bottom: 15px;
}

.containerYourPage {
    width: calc(100% - 430px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1; /* Pour prendre tout l'espace disponible */
}


.titleYourPage{
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    width: 100%;
    margin: 0 0 10px 0;
    text-transform: capitalize;
}

.containerConfigYourPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.containerBlockList {
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding: 10px;
}
.buttonAddNewBlock {
    cursor: pointer;
    justify-content: center;
    gap: 5px;
    background-color: var(--accent);
    border-radius: 10px;
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px 0;
}
.buttonAddNewBlockDisabled {
    cursor: not-allowed;
    background-color: #cccccc; /* A grey color to indicate disabled state */
    color: #666666; /* A lighter color for the text */
    opacity: 0.6; /* Reduce opacity to further indicate it's disabled */
    justify-content: center;
    gap: 5px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px 0;
}
.containerApparence{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 15px;
}
.containerSettingsApparence{
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100%);
    padding: 10px;
    gap: 20px;
}
.titleSettingsApparence{
    text-transform: capitalize;
    font-weight: bold;
    text-align: left;
    width: 100%;
    padding: 0 0 10px 0;
    margin: 0;
}
.containerEditProfilImg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}
.ImgEditProfil {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--accent);
}

.ImgEditProfil > i {
    font-size: 95px;
    margin: 25px 0 0 0;
}
.containerInputFirstnameLastname {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
}
.containerInputFirstnameLastname > div {
    width: auto;
}
.containerDisplayEmail{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
}
.containerDisplayEmailPhone{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.containerDisplayEmailPhone > div > div > div > input {
    width: 200px !important;
    padding: 5px 10px;
    height: 30px !important;
    overflow: hidden;
    border-radius: 10px !important;
    border-color: var(--text-secondary) !important;
}
.containerDisplayEmailPhone > div > div > div > div {
    background-color: var(--text-secondary) !important;
}
.containerDisplayEmailPhone > div > div {
    width: auto;
}
.flag-dropdown {
    background-color: var(--text-secondary) !important;
    border: none !important;
    border-radius: 10px 0 0 10px !important;
}
.arrow {
    border-top: 4px solid var(--text-primary) !important;
}
.emailDisplayEmail {
    margin: 0;
}
.emailDisplayEmail > span {
    color: var(--accent);
    font-size: 12px;
    margin-left: 8px;
}
.emailDisplayEmail > span > i {
    color: var(--accent);
    font-size: 12px;
}
/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    border: 1px solid var(--text-secondary) ;
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width:20px;
    left: 1px;
    bottom: 1px;
    background-color: var(--background-primary);
    border-radius: inherit;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.320, 1);
}

.switch input:checked + .slider:before {
    background-color: var(--accent);
    transform: translateX(20px);
}

.containerFlagContry{
    height: 100%;
    background-color: var(--accent);
    border-radius: 10px;
}
.country-selector {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 0 0 8px;
    background-color: var(--accent);
    border: 2px solid var(--accent);
}

.country-select {
    font-size: 16px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    outline: none; /* Remove default outline */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for focus effect */
}

.country-select:focus {
    border: none;
}

.country-flag {
    width: 64px;
    height: 64px;
    border-radius: 4px;
}

.containerSubmitChange{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.containerSubmitChange button {
    margin: 0;
}
.headerSettingsApparence {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttonGroup {
    display: flex;
    gap: 10px;
}

@media (min-width: 1500px) {
    .ContainerPreviewHobsSpace {
        min-width: 435px;
        max-width: 435px;
    }
}