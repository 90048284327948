.Profil{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    position: relative;
}

.bannerProfil{
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
}

.containerProfil{
    position: absolute;
    padding: 0 0 0 120px;
    height: 100px;
    top: 15px;
    background-color: var(--card-background);
    border: var(--border) 1px solid;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
}

.containerTextProfil {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    height: 100%;
}

.containerImgProfil{
    width: 90px;
    height: 90px;
    border-radius: 50px;
    padding: 6px;
    bottom: 30px;
    position: absolute;
    left: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerImgProfil > i {
    font-size: 90px;
    margin: 25px 0 0 0;
}
.containerImgProfil.active {
    padding: 0;
    background-color: var(--accent);
}
.imgUserProfil{
    height: 100%;
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
}
.containerInfoUser{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    padding: 0 20px;
    gap: 5px;
}
.countryProfil {
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    gap: 5px;
    align-items: center;
    padding: 3px 5px;
    justify-content: center;
}
.nameCountryProfil{
    font-size: 10px;
    text-transform: capitalize;
    color: var(--accent);
    margin: 0;
    line-height: 10px;
}
.nameUserProfil{
    margin: 0;
    font-weight: bold;
    color: var(--text-primary);
    font-size: 20px;
    text-transform: capitalize;
    text-align: left;
}
.emailUserProfil{
    margin: 0;
    font-size: 12px;
    text-transform: lowercase;
    text-align: left;
    color: var(--text-secondary);
}

.profilLine {
    background-color: var(--text-secondary);
    height: 80%;
    width: 1px;
    border-radius: 2px;
}

.containerHobsSpaceUserProfil{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 20px;
    height: 90%;
}
.containerTopHobsSpaceUserProfil{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.titleHobsSpaceUserProfil{
    font-size: 16px;
    font-weight: bold;
    color: var(--text-primary);
    text-align: left;
    text-transform: capitalize;
    margin: 0;
}

.containerBottomHobsSpaceUserProfil{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.containerBttomHobsSpaceUserProfil{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}
.containerCustomUrlProfil {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.linkUrlProfil{
    text-decoration: none;
    color: var(--accent);
    font-size: 11px;
}
.iconButtonUrlProfil > i {
    font-size: 12px;
}
.iconButtonUrlProfil {
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerUrlUserProfil {
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.shareUrlHobsSpace {
    border-radius: 5px;
    background-color: var(--accent);
    padding: 5px 10px;
    border: none;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.textShareUrlHobsSpace {
    color: var(--text-primary);
    font-size: 12px;
    text-transform: capitalize;
    margin: 0;
}

.linkUrlHobsSpace {
    color: var(--text-primary);
    font-size: 12px;
    margin: 0;
}

.textCopy {
    display: none;
    position: absolute;
    background-color: var(--accent); /* Vert pour succès */
    color: var(--text-secondary);
    padding: 2px 4px;
    font-size: 10px;
    left: 50%;
    top: 30px;
    margin: 0;
    border-radius: 4px;
    text-align: center;
}

.textCopy::after {
    content: "";
    position: absolute;
    top: -8px; /* Place le triangle en bas du div */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    transform: rotate(180deg);
    border-color: var(--accent) transparent transparent transparent; /* Triangle vert */
}

.textCopy.visible {
    display: block;
}
