.Title {
    height: auto;
    text-align: left;
    font-weight: 500;
    width: 100%;
    font-size: 15px; 
    margin: 0;
}

.Title.demo {
    width: 50%;
    font-size: 18px; 
    margin: 0;
}

@media (max-width: 500px) {
    .Title {
        width: 100%;
    }
}
