.LinkConcert{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 240px;
    height: 70px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
}
.containerInfoConcert{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
}
.containerInfoConcert > p{
    margin: 0;
}
.event{
    font-size: 10px;
    text-transform: capitalize;
}
.where{
    text-transform: capitalize;
    font-size: 8px;
    margin: 0px 0 5px 0 !important;
}
/* .dateConcert{
    background-color: rgba(0, 0, 0, 1);
    border-top-left-radius:10px ;
    border-bottom-left-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.containerDateConcert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0px 8px;
    gap: 2px;
    padding: 3px 5px;
}
.containerDateConcert > p {
    margin: 0 auto;
}
.mounth{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 8px;
}
.day{
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}
.year{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 8px;
}
.separationTicket{
    height: 100%;
    width: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bulles{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    gap: 6px;
}
.containerInfoConcert > p {
    margin: 0;
}
.ticketOk {
    border-radius: 5px;
    text-decoration: none;
    font-size: 10px;
    line-height: 10px;
    padding: 3px 5px;
    border: 1px solid #f1f1f1;
}

.ticketSoldout {
    font-size: 10px;
    line-height: 10px;
    padding: 3px 0px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    text-decoration: line-through;
}
.bulle {
    padding: 5.5px;
    border-radius: 999px;
    position: relative;
}

.bulle.top {
    top: -4px;
}

.bulle.bottom {
    bottom: -4px;
}

.bul {
    padding: 2.5px;
    border-radius: 999px;
}

@media (max-width: 650px) {  
    .TicketConcert {
        width: 300px;
    }
    .event {
        font-size: 14px;
        text-align: left;
    }
    .containerDateConcert {
        margin: 0px 15px;
    }
}
@media (max-width: 360px) {  
    .TicketConcert {
        width: 270px;
    }
    .containerInfoConcert {
        padding: 15px;
    }
}