.div-banner {
    position: relative;
    width: 100%;
    height: 60px;
}

.header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
  
.coverWrapper {
    position: relative;
}
  
.cover {
    position: absolute;
    width: 100%;
    height: 130px;
    top: 0;
    left: 0;
    z-index: 0;
}
