.LinkTikTok{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto; 
    gap: 5px;
    padding: 0px;
    width: auto;
    border-radius: 10px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}
.LinkTikTok-thumbnail {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
.container-player-tiktok {
    width: 322px;
    height: 558px;
    background: #000;
    overflow: hidden;
    border-radius: 8px;
}
.player-tiktok {
    display: block;
    width: 322px;
    height: 800px;
    outline: 0;
    border: 0;
    margin: 0 auto;
    overflow: hidden;
    transform: translateY(-1px);
}
.container-player-short {
    width: 322px;
    height: 558px;
    background: #000;
    overflow: hidden;
    border-radius: 8px;
}
.player-short {
    display: block;
    width: 322px;
    height: 100%;
    outline: 0;
    border: 0;
    margin: 0 auto;
    overflow: hidden;
    transform: translateY(-1px);
}
.container-player-logo {
    width: 144px;
    height: 240px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.player-logo {
    height: 50px;
    width: auto;
    object-fit: contain;
    object-position: center;
}