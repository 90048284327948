.ReseauSocial{
    cursor: pointer;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    border: none;
}
.ReseauSocial i{
    font-size: 16px;
}
.ReseauSocial.qrcode {
    margin-top: 2px;
}
@media screen and (min-width: 300px) and (max-width: 460px) {  
    .ReseauxSociaux {
        flex-wrap: wrap;
        width: 90%;
    }
}