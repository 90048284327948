.ButtonMenu{
    font-size: 14px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    width: 92%;
    padding: 0 8px;
    gap: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
}
.ButtonMenu:hover {
    background-color: var(--hover);
}
.ButtonMenu.active {
    background-color: var(--accent);
}
.ButtonMenu.active:hover {
    background-color: var(--hover);
}
.iconButton{
    margin-right: 2px;
}
.menu-content {
    transition: all 0.3s ease-in-out;
    width: 92%;
}
.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s;
}
.rotate-180 {
    transform: rotate(0deg);
    transition: transform 0.3s;
}
  