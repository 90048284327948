.LinksTikTok > div > div > div > div {
    width: 120px;
    height: auto;
}
.LinksTikTok > div > a {
    width: 100%;
}
.LinksTikTok > .Slider > div > div > .swiper-slide {
    margin-right: 8px;
}
.LinksTikTok > .Slider > div > div > .swiper-slide > div {
    width: 144px !important;
}

.SliderLinksTiktok {
    padding: 5px 0 10px 0;
    position: relative;
    overflow: hidden;
    width: 322px;
}
.SliderLinksTiktok > div{
    overflow: visible !important;
    margin-top: 8px !important;
}
.SliderLinksTiktok > div > div .swiper-slide{
    width: min-content !important;
}
.SliderLinksTiktok > div > div .swiper-slide > div {
    width: 322px !important;
}
.mySwiperLinksTiktok > div:nth-child(2) {
    display: none;
}
.mySwiperLinksTiktok > div:nth-child(3) {
    display: none;
}
.LinkTikTok-icons {
    width: 322px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    gap: 10px;
}
.LinkTikTok-iconBtn.active {
    border: #FFF 1px solid;
    border-radius: 99px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}