.ReseauxSociaux{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 80%;
    height: auto;
    margin: 10px auto;
    position: relative;
}