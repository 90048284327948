.Contact {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
}

.contact-container h1 {
    color: #333;
    font-size: 38px;
    margin-bottom: 20px;
    font-weight: 800;
}
.contactLinkSupport{
    text-decoration: underline;
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
}
.contact-container p {
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.form-group label {
    margin-bottom: 5px;
    color: #000 ;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.form-control {
    width: 550px;
    height: 50px;
    padding: 12px;
    box-sizing: border-box; /* Ajoutez cette ligne */
    border: 1px solid #000;
    border-radius: 99px;
    font-size: 16px;
    outline: none;
}
.form-control::placeholder{
    font-size: 14px;
    color: #a8a8a8;
}
.form-control.area {
    border-radius: 0.5em;
}

textarea.form-control {
    height: 150px;
}

.form-group select::after {
    right: 10px;
    pointer-events: none; /* Évite que le pseudo-élément interfère avec le clic sur le select */
    color: #000; /* Couleur de la flèche */
}
input:focus {
    outline: none; 
  }