.draggable-block {
    padding: 10px;
    border-radius: 10px;
    cursor: default !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.gripDot.dragAndDrop .dot {
    background-color: rgba(0, 0, 0, 1);
    padding: 2px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    scale: 1;
    align-items: center;
    justify-content: center;
    margin: 2px 0 0 2px;
}
