.ButtonEditImage{
    color: white;
    text-decoration-line: none;
    text-align: center;
    background-color: var(--accent);
    padding: 8px 16px;
    font-size: 14px;
    margin: 20px auto;
    border-radius: 50px;
    border: var(--text-primary) solid 2px;
    box-shadow: 0 0.3em 0 var(--text-primary);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.titleModalSettings {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
}
.containerWarningComponent {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    height: 100%;
    padding: 0 2px;
    cursor: pointer;
}
.containerWarningComponent i {
    font-size: 12px;
    transition: all 0.3s ease-in-out;
}
.containerWarningComponent:hover i {
    color: rgb(255, 162, 0);
}
.containerWarningComponent p {
    position: absolute;
    padding: 2px 4px;
    background-color: rgb(255, 162, 0);
    font-size: 10px;
    color: #fff;
    border-radius: 5px;
    right: -148px;
    width: auto;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 5;
    text-transform: initial ;
}
/* .containerWarningComponent p::after {
    content: "";
    position: absolute;
    top: 4px;
    left: -4px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    transform: rotate(90deg);
    border-color: red transparent transparent transparent;
} */
.containerWarningComponent:hover p {
    opacity: 1;
}
.containerSelectImgProfil{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    background-color: var(--background-secondary);
    border: var(--border) 1px solid;
    border-radius: 10px;
    padding: 20px 10px;
}
.dropzone {
    border: .125rem dashed var(--text-secondary);
    border-radius: 10px;
    padding: 15px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.dropzone:hover {
    border-color: var(--accent);
}
.dropzone:hover p {
    color: var(--accent);
}
.dropzone p {
    margin: 0;
    transition: all 0.2s ease-in-out;
    color: var(--text-secondary);
}
.iconButtonImgProfil > i{
    color: var(--text-secondary);
    font-size: 40px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
}
.dropzone:hover i{
    color: var(--accent);
}
.imgUploadProfil {
    object-fit: cover;
    object-position: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: var(--accent) 2px solid;
}
.containerButtonImgProfil{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
}

.hide-scrollbar {
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; 
  }
  