.LinkMusic{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    height: 70px; 
    margin: 0px 0; 
    width: 100%;
    border-radius: 10px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.music{
    height: 70px;
    min-width: 70px;
    object-fit: cover;
    object-position: center;
}
.containerInfoMusic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    position: relative;
    height: 100%;
    width: calc(100% - 70px);
}
.containerInfoMusic > p{
    margin: 0;
}
.title{
    font-size: 12px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
}
.singer{
    text-transform: capitalize;
    font-size: 10px;
    margin: 0px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
}
.play-pause-button {
    position: absolute;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: all 0.2s ease;
}
.play-pause-button-modal {
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.75rem;
    left: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
}
.play, .pause{
    border-radius: 50px;
    text-decoration: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.play i, .pause i{
    font-size: 14px;
}
.more{
    border-radius: 50px;
    text-decoration: none;
    font-size: 11px;
    line-height: 11px;
    cursor: pointer;
    padding: 2px 5px;
    background-color: transparent;
}
.containerButtonLinkMusic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;
    margin-top: 6px;
}

.containerImageDefaultMusic {
    height: 70px;
    min-width: 70px;
    max-width: 70px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.containerImageDefaultMusic i {
    color: white;
    font-size: 515%;
}
.searchBarWrapper {
    position: relative;
    width: 100%;
}

.resultItem {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
    transition: all 0.2s ease;
    cursor: pointer;
    border-radius: 10px;
}
.resultItem.hovered {
    background-color: #fff;
    border-color: #ccc;
}
.platformIcons {
    display: flex;
    gap: 5px;
}
.platformIcon {
    min-width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}
.songImage {
    min-width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.titleSingerSong {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.platformSelection {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.platformButton.selected {
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(270deg, #17e391, #58e5df, #60e5e8) border-box;
    border: 1px solid transparent;
}

