.LinkConcert{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 140px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
}
.containerInfoConcert{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px;
    width: 100%;
    height: 90%;
}
.containerInfoConcert > p{
    margin: 0;
}
.event{
    font-weight: 600;
    text-transform: capitalize; 
    text-align: left;
    margin: 0;
}
.where{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
    margin: 4px 0 16px 0 !important;
    font-weight: 400;
    text-align: left;
}
/* .dateConcert{
    background-color: rgba(0, 0, 0, 1);
    border-top-left-radius:10px ;
    border-bottom-left-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.containerDateConcert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    margin: 0px 12px;
    padding: 8px;
}
.containerDateConcert > p {
    margin: 0 auto;
}
.mounth{
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.day{
    font-size: 40px ;
    line-height: 48px;
    font-weight: 700;
    text-transform: uppercase;
}
.year{
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.separationTicket{
    height: 100%;
    width: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bulles{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    gap: 10px;
}
.containerInfoConcert > p {
    margin: 0;
}
.ticketOk {
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 12px;
    border: 1px solid #f1f1f1;
}

.ticketSoldout {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    text-decoration: line-through;
}

.bulleTop {
    top: -10px;
    padding: 10.5px;
    border-radius: 999px;
    position: relative;
}

.bulleBottom {
    bottom: -10px;
    padding: 10.5px;
    border-radius: 999px;
    position: relative;
}

.bul {
    padding: 5.5px;
    border-radius: 999px;
}

@media (max-width: 425px) {  
    .containerDateConcert{
        width: 70px;
    }
    .mounth{
        font-size: 12px;
        line-height: 20px;
    }
    .day{
        font-size: 34px ;
        line-height: 38px;
    }
    .year{
        font-size: 12px;
        line-height: 20px;
    }
    .bulles {
        gap: 9px;
    }
    .bulles div:nth-child(1) {
        display: none;
    }
    .LinkConcert {
        height: 120px;
    }
    .ticketOk {
        font-size: 14px;
        line-height: 22px;
    }
    
    .ticketSoldout {
        font-size: 14px;
        line-height: 22px;
    }
    .where{
        margin: 4px 0 8px 0 !important;
    }
}

@media (max-width: 375px) {  
    .containerDateConcert{
        width: 60px;
    }
    .mounth{
        font-size: 10px;
        line-height: 18px;
    }
    .day{
        font-size: 30px ;
        line-height: 34px;
    }
    .year{
        font-size: 10px;
        line-height: 18px;
    }
}
@media (max-width: 350px) {  
    .ticketOk {
        font-size: 12px;
        line-height: 20px;
    }
    .ticketSoldout {
        font-size: 12px;
        line-height: 20px;
    }
    .LinkConcert {
        height: 100px;
    }
    .day{
        font-size: 26px ;
        line-height: 30px;
    }
    .containerDateConcert{
        width: 50px;
        padding: 5px;
    }
    .bulles div:nth-child(2) {
        display: none;
    }
}

@media (max-width: 320px) {  
    .LinkConcert {
        width: 280px;
    }
}