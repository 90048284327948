.LinkPodcast{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto; 
    gap: 5px;
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.music, .containerImageDefaultMusic{
    height: 120px;
    min-width: 120px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
}
.containerInfoMusic > p{
    margin: 0;
}
.containerImageDefaultMusic i{
    font-size: 880%;
}
.containerInfoMusic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    position: relative;
    width: calc(100% - 120px);
}
.containerInfoMusic > div > i {
    font-size: 20px;
}
.titlePodcast{
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    text-align:left;
    line-height: 22px;
    height: 100%;
    margin: 0 0 2px 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.singer{
    text-transform: capitalize;
    font-size: 18px;
    margin: 0px 0 15px 0 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    text-align: left;
}
.playPausePodcast {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.playPodcast, .pausePodcast{
    border-radius: 50px;
    text-decoration: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playPodcast i, .pausePodcast i{
    font-size: 16px;
}
@media (max-width: 425px) {  
    .playPodcast i, .pausePodcast i{
        font-size: 14px;
    }
    .playPodcast, .pausePodcast{
        height: 25px;
        width: 25px;
    }
}
@media (max-width: 350px) {  
    .playPodcast i, .pausePodcast i{
        font-size: 12px;
    }
    .playPodcast, .pausePodcast{
        height: 20px;
        width: 20px;
    }
}

@media (max-width: 768px) {  
    .titlePodcast {
        font-size: 18px;
        line-height: 20px;
    }
    .singer {
        font-size: 16px;
    }
    .containerInfoMusic > div > i {
        font-size: 18px;
    }
}

@media (max-width: 425px) {  
    .music, .containerImageDefaultMusic {
        height: 100px;
        min-width: 100px;
        max-width: 100px;
    }
    .containerImageDefaultMusic i {
        font-size: 735%;
    }
    .containerInfoMusic {
        width: calc(100% - 100px);
    }
    .titlePodcast {
        font-size: 16px;
        line-height: 18px;
    }
    .singer {
        font-size: 14px;
        margin: 0px 0 10px 0 !important;
    }
}

@media (max-width: 350px) {  
    .music, .containerImageDefaultMusic {
        height: 80px;
        min-width: 80px;
        max-width: 80px;
    }
    .containerInfoMusic > div > i {
        font-size: 16px;
    }
    .containerImageDefaultMusic i {
        font-size: 585%;
    }
    .containerInfoMusic {
        width: calc(100% - 80px);
    }
    .titlePodcast {
        font-size: 14px;
        line-height: 16px;
    }
    .singer {
        font-size: 12px;
        margin: 0px 0 5px 0 !important;
    }
}