.Link {
    height: 140px; 
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.containerImageDefault {
    height: 140px;
    min-width: 140px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.containerImageDefault i {
    font-size: 1025%;
}
.imgTicket {
    height: 140px; 
    width: 140px; 
    object-fit: cover;
    object-position: center;
}

.infoTicket {
    width: 100%;
    height: 100%;
    padding:0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.titleTicket {
    font-weight: 600;
    /* text-transform: capitalize; */
    text-align: left;
    margin: 0;
}

.priceTicket {
    font-weight: 400;
    text-align: left;
    margin: 0;
}

@media (max-width: 768px) {  
    .Link {
        height: 120px;
    }
    .File {
        height: 100px;
    }
    .containerImageDefault {
        height: 120px;
        min-width: 120px;
    }
    .containerImageDefault i {
        font-size: 880%;
    }
    .imgTicket {
        height: 120px; 
        width: 120px; 
    }
}

@media (max-width: 425px) {  
    .Link {
        height: 100px;
    }
    .File {
        height: 100px;
    }
    .containerImageDefault {
        height: 100px;
        min-width: 100px;
    }
    .containerImageDefault i {
        font-size: 735%;
    }
    .imgTicket {
        height: 100px; 
        width: 100px; 
    }
}

@media (max-width: 350px) {  
    .Link {
        height: 80px;
    }
    .File {
        height: 100px;
    }
    .containerImageDefault {
        height: 80px;
        min-width: 80px;
    }
    .containerImageDefault i {
        font-size: 585%;
    }
    .imgTicket {
        height: 80px; 
        width: 80px; 
    }
}