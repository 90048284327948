.qr-code-button {
  border: none;
  border-radius: 16px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 1;
}
.qr-code-add-contact svg {
  border-radius: 27px;
}
.qr-code-button i {
  font-size: 20px;
}

.qr-code-modal {
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.qr-code-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.sectionQRCode {
  border-radius: 5px;
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

}
.containerQRCode{
  display: flex;
  border-radius: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFF;
  overflow: hidden;
}

.container-profil-add-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  gap: 10px;
  width: 100%;
}
.container-profil-add-contact img {
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  object-position: center;
}

.profil-add-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 70px);
  gap: 5px;
}
.profil-add-contact p {
  margin: 0;
  width: 100%;
  text-align: left;
}
.add-contact-button {
  width: 100%;
  background-color: #FFF;
  border: none;
  border-radius: 30px;
  color: #ff8500;
  color: #000;
  padding: 8px 0;
  font-size: 10px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}

.qr-code {
  border-radius: 5px;
}