.LinksTikTok > div > div > div > div {
    width: 180px;
    height: auto;
}
.LinksTikTok > div > a {
    width: 100%;
}
.LinksTikTok > .Slider > div > div > .swiper-slide {
    margin-right: 8px;
}
.LinksTikTok > .Slider > div > div > .swiper-slide > div {
    width: 144px !important;
}

.LinkTikTokIcons {
    width: 322px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    gap: 10px;
}
.LinkTikTokIconBtn.active {
    border: #FFF 1px solid;
    border-radius: 99px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerPlayerLogo {
    width: 144px;
    height: 240px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playerLogo {
    height: 50px;
    width: auto;
    object-fit: contain;
    object-position: center;
}