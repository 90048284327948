.Footer{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 80px 0 40px 0;
    background-color: #ff8500;
    border-radius:30px 30px 0 0;
    margin-top: 50px;
}
.sectionFooter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
    gap: 30px;
    padding: 40px;
    width: calc(80% - 80px);
    /* height: calc(320px - 80px); */
    height: calc(320px - 80px);
    background-color: #fba141;
}
.sectionFooterTop{
    display: flex;
    width: 100%;
    height: auto;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;

}
.containerFooter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    height: auto;
}
.containerFooterTitle{
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    margin: 8px 0;
}
.containerFooterLink{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
.containerLogoFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    width: 80%;
    height: 250px;
    position: relative;
    overflow: hidden;
    background-color: #000;
}
.LogoFooter {
    object-fit: contain;
    object-position: center;
    height: 110%;
    width: auto;
}
.sectionFooterBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: auto;
}
.ligneFooter {
    width: 80%;
    background-color: #fff;
    height: 1px;
    margin: 10px auto 0px auto;
    opacity: 0.3;
}
.containerCopyRight{
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyRight{
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}
.linkFooter{
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
}
.linkFooterReseau{
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    background-color: #000;
    height: 35px;
    width: 35px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.containerReseaux{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.logoReseauFooter{
    height: 25px;
    width: 25px;
    object-fit: contain;
    object-position: center;
}

.logoReseauFooter.invert{
    filter: invert(100%);
}

@media (max-width: 768px) {
    .sectionFooter {
        width: calc(90% - 80px);
    }
    .containerLogoFooter {
        border-radius: 40px;
        width: 90%;
    }
}
@media (max-width: 645px) {
    .sectionFooter {
        height: auto;
        width: auto;
    }
    .containerLogoFooter {
        height: 200px;
    }
    .sectionFooterTop {
        gap: 20px;
        align-items: center;
        flex-direction: column;
    }
    .containerFooter {
        align-items: center;
        justify-content: center;
    }
    .containerFooterLink {
        align-items: center;
    } 
    /* .sectionFooterTop {
        flex-wrap: wrap;
        gap: 20px;
    }
    .containerFooter:nth-child(2) {
        align-items: flex-end;
    }
    .containerFooter:nth-child(3) > div {
        align-items:center;
    }
    .containerFooter:nth-child(3) {
        align-items:center;
        justify-content: center;
        width: 100%;
    } */
}
@media (max-width: 550px) {
    .containerLogoFooter {
        height: 150px;
        width: 80%;
        border-radius:30px;
    }
    .LogoFooter {
        height: 100%;
    }
}
@media (max-width: 425px) {
    .containerLogoFooter {
        height: 100px;
        width: 308.75px;
        border-radius:30px;
    }
}