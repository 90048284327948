.inputCheckboxSelectableComponent {
    display: none;
}  
.checkmark {
    min-width: 13px;
    height: 13px;
    border: 1px solid var(--text-primary); /* Couleur de la bordure */
    border-radius: 4px; /* Coins arrondis */
    display: inline-block;
    position: relative;
    margin-left: 8px;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}  
.checkmark.checked {
    background-color: var(--accent); /* Couleur de fond lorsqu'elle est cochée */
    border-color: var(--text-primary);
}
.checkmark.checked::after {
    content: '';
    position: absolute;
    top: 1px;
    right: 3px;
    width: 4px;
    height: 8px;
    border: solid var(--text-primary);
    border-width: 0 1.5px 1.5px 0;
    border-radius: 1px;
    transform: rotate(45deg);
  }
.checkbox-label {
    margin-left: 10px;
    font-size: 12px;
    color: #333;
    text-align: left;
}

.signup-link.signup {
    font-size: 12px;
    margin-top: 20px;
}

.signup-link.signupTerm {
    font-size: 12px;
}

#hobs {
    padding-left: 95px;
    padding-bottom: 9px;
    font-size: 14px;
    width: 100%;
    text-transform: lowercase;
}

.labelSignUpHobs {
    position: absolute;
    margin-left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 1;
    font-weight: 600;
}

.buttonEyesPassword {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 20px;
    top: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#email {
    text-transform: lowercase;
}
.containerStep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 35px 0 20px 0;
    width: 100%;
    background-color: var(--card-background);
}

.step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step p {
    background-color: var(--text-secondary);
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    color: var(--card-background);
    margin: 0;
    align-items: center;
    justify-content: center;
}

.stepLine {
    width: 100px;
    height: 3px;
    background-color: var(--text-secondary);
}

.stepLine.active {
    background-color: var(--accent);
}

.step.active p {
    background-color: var(--accent);
}

.step span {
    color: var(--text-secondary);
    position: absolute;
    bottom: 26px;
    font-size: 14px;
}

.step span.active {
    color: var(--accent);
}

.sectionLayoutConfig {
    width: 100%;
    background-color: var(--background-secondary);
    height: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.containerConfigProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 400px;
    border-radius: 20px;
    margin-top: 14px;
    padding-bottom: 20px;
    border: 1px solid var(--text-primary);
    overflow: hidden;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
}

.containerLayoutConfig {
    width: 60%;
    height: 80%;
    padding: 20px 10px 10px 10px;
    background-color: #000;
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.sectionConfigProfile .containerTitleLogin {
    width: 500px;
}

.containerButtonConfigProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.containerConfigProfile .containerInputFirstnameLastname {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    margin: 0 0 20px 0;
}

.containerConfigProfile .containerInputFirstnameLastname>div {
    width: 80%;
}

.containerConfigProfile .containerInputFirstnameLastname>div>div input {
    margin-top: 5px;
}

.containerNameConfig {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-bottom: 8px;
}

.containerNameConfig p {
    color: var(--text-primary);
    font-weight: 700;
    font-size: 18px;
}

.ImgConfigProfil {
    width: 100px;
    height: 125px;
    margin-bottom: 10px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImgConfigProfil i {
    font-size: 95px;
    margin: 0;
}

.containerTextConfigProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin-top: 20px;
}

.containerTextConfigProfile h1 {
    font-size: 18px;
    margin: 0;
    width: 100%;
}

.containerTextConfigProfile p {
    margin: 5px 0 10px 0;
    font-size: 14px;
    width: 100%;
}

.containerNameConfig i {
    color: var(--text-primary);
    font-size: 25px;
}

.socialLinkDash {
    border-radius: 5px;
    height: 20px;
    width: 20px;
    border: 2px dashed var(--text-primary);
    margin: 0 2px 0 2px;
}

@media (max-width: 768px) {
    .containerTitleLogin {
        width: 90% !important;
    }
}

@media (max-width: 600px) {
    .sectionConfigProfile {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .sectionSignup {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .containerConfigProfile {
        width: 300px;
    }

    .stepLine {
        width: 60px;
    }
}