.playPausePodcast {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.playPodcast, .pausePodcast{
    border-radius: 50px;
    text-decoration: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playPodcast i, .pausePodcast i{
    font-size: 16px;
}
@media (max-width: 425px) {  
    .playPodcast i, .pausePodcast i{
        font-size: 14px;
    }
    .playPodcast, .pausePodcast{
        height: 25px;
        width: 25px;
    }
}
@media (max-width: 350px) {  
    .playPodcast i, .pausePodcast i{
        font-size: 12px;
    }
    .playPodcast, .pausePodcast{
        height: 20px;
        width: 20px;
    }
}