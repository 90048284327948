.buttonDisplayEmailPhone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 0 auto;
    gap: 3px;
    background-color: transparent;
    border: none;
}
.buttonDisplayEmailPhone p {
    font-size: 12px;
    margin: 0;
}

.buttonDisplayEmailPhone > div > i {
    margin-top: 2px;
}