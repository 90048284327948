.NavigationDashboard{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 70px;
    background-color: var(--background-primary);
    width: calc(100% - 230px);
    position: relative;
    transition: all 0.3s ease-in-out;
}
.logo.logoDashboard{
    height: 40px;
    width: auto;
    object-position: center;
    object-fit: contain;
}
.containerLogoDashboard{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 0;
    min-height: 70px;
    padding: 0 20px;
    width: 100%;
}
.containerNavigation {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    background-color: var(--background-primary);
}
.containerTitleDashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}
.containerMenuDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-color: var(--background-primary);
    width: 230px;
    position: fixed;
    top: 0px;
    left: 0;
}
.titleContainerNavication{
    color: var(--text-primary);
    font-size: 12px;
    text-transform: capitalize;
    padding: 0 0 0 20px;
    margin: 15px 0;
}