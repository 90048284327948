.Home {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #ff8500;
}

.backgroundImage {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  opacity: .8;
  height: 100vh;
  filter: blur(104px);
}
.containerHome {
  margin: 80px auto;
  border-radius: 40px;
  overflow: hidden;
}
.containerHomeContent {
  position: relative; /* Contenu au-dessus de l'image */
  padding: 80px 74px;
  width: 735px;
  z-index: 3;
  overflow: hidden; /* Empêche les débordements */
}
.HomeImage{
  width: 100%;
  max-width: 735px;
  object-fit: cover;
  object-position: center;
  height: 100vh;
  position: absolute;
}
.lineSeparation{
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 20px auto;
}
.containerSpinner{
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.spinner {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
.spinner circle {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }
 

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {  
  .containerHomeContent{
    padding: 80px 50px;
    max-width: 100%;
    box-shadow: rgba(255, 255, 255, 0) 0px 0px 50px 10px !important;
  }
  .containerHome {
    margin: auto;
    border-radius: 0px;
    max-width: 100%;
  }
  .HomeImage{
    max-width: 100%;
  }
}
@media (max-width: 640px) {  
  .containerHomeContent{
    padding: 80px 30px;
  }
}

@media (max-width: 500px) {  
  .containerHomeContent{
    padding: 60px 10px;
  }
}
