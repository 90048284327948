.descriptionOverflow {
    width: 100%;
}

@media (max-width: 1300px) {
    .descriptionOverflow {
        width: 500px;
    }
}

@media (max-width: 1200px) {
    .descriptionOverflow {
        width: 400px;
    }
}

@media (max-width: 1100px) {
    .descriptionOverflow {
        width: 300px;
    }
}