.section-faq{
    width: auto;
    margin: 50px auto 50px auto;
    overflow: hidden;
    height: auto;
    display: flex;
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    background-color: #fff;
    padding: 20px 80px;
    border-radius: 20px;
    filter: drop-shadow(0 4px 34px rgba(0, 0, 0, .08));
}
.faq-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 0px;
    text-align: center;
    color: #252C32;
}
.faq-button:hover{
    color: rgba(18, 20, 29, 0.6);
}
.faq-button{
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: rgba(18, 20, 29, 0.6);
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    width: auto;
    margin: 35px 0 0 0;
    background-color: transparent;
    height: 54px;
    border: 2px solid rgba(18, 20, 29, 0.6);
    border-radius: 16px;
    display: -webkit-flex;
    -webkit-flex-direction: row; 
    -webkit-align-items: center;
    -webkit-justify-content: center; 
}
.container-questions{
    width: 60vw;
    min-width: 425px;
    margin: 30px 0 0 0;
    height: auto;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: column; 
    -webkit-align-items: center;
    -webkit-justify-content: center;
}
@media (max-width: 1200px) {
    .container-questions{
        width: 60vw;
    }
}
@media (max-width: 900px) {
    .container-questions{
        width: 80vw;
    }
    .section-faq{
        padding: 20px 40px;
    }
}
@media (max-width: 550px){
    .section-faq {
        padding: 20px;
        width: calc(90% - 40px);
    }
    .container-questions {
        width: 90%;
        min-width: auto;
    }
}