.LinkMusic{
    height: 140px; 
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}
.music, .containerImageDefaultMusic{
    height: 140px;
    width: 140px;
    object-fit: cover;
    object-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.containerImageDefaultMusic i{
    font-size: 1025%;
}
.containerInfoMusic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    position: relative;
    width: calc(100% - 140px);
}
.containerInfoMusic > p{
    margin: 0;
}
.title{
    font-size: 20px;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    text-align: left;
}
.singer{
    text-transform: capitalize;
    font-size: 18px;
    margin: 0px 0 15px 0 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    text-align: left;
}

.playPauseButton {
    position: absolute;
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: all 0.2s ease;
}
.playPauseButtonModal {
    position: absolute;
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1.64rem;
    left: 1.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
}
.play, .pause{
    border-radius: 50px;
    text-decoration: none;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.play i, .pause i{
    font-size: 22px;
}
.more{
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    padding: 5px 10px;
    background-color: transparent;

}
.containerButtonLinkMusic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}
@media (max-width: 768px) {  
    .LinkMusic {
        height: 120px;
    }
    .music, .containerImageDefaultMusic {
        height: 120px;
        min-width: 120px;
        max-width: 120px;
    }
    .containerImageDefaultMusic i {
        font-size: 880%;
    }
    .containerInfoMusic {
        width: calc(100% - 120px);
    }
    .title {
        font-size: 18px;
    }
    .singer {
        font-size: 16px;
    }
    .playPauseButton {
        height: 120px;
        width: 120px;
    }
    .play, .pause{
        height: 45px;
        width: 45px;
    }
    .play i, .pause i{
        font-size: 20px;
    }
}

@media (max-width: 640px) {
    .playPauseButtonModal {
        height: 120px;
        width: 120px;
        bottom: 1.5rem;
        left: 1.24rem;
    }
}

@media (max-width: 425px) {  
    .LinkMusic {
        height: 100px;
    }
    .music, .containerImageDefaultMusic {
        height: 100px;
        min-width: 100px;
        max-width: 100px;
    }
    .containerImageDefaultMusic i {
        font-size: 735%;
    }
    .containerInfoMusic {
        width: calc(100% - 100px);
    }
    .title {
        font-size: 16px;
    }
    .singer {
        font-size: 14px;
        margin: 0px 0 10px 0 !important;
    }
    .more {
        font-size: 14px;
        padding: 3px 8px;
    }
    .playPauseButton {
        height: 100px;
        width: 100px;
    }
    .play, .pause{
        height: 40px;
        width: 40px;
    }
    .play i, .pause i{
        font-size: 18px;
    }
    .playPauseButtonModal {
        height: 90px;
        width: 90px;
        bottom: 0.95rem;
        left: 0.75rem;
    }
}

@media (max-width: 350px) {  
    .LinkMusic {
        height: 80px;
    }
    .music, .containerImageDefaultMusic {
        height: 80px;
        min-width: 80px;
        max-width: 80px;
    }
    .containerImageDefaultMusic i {
        font-size: 585%;
    }
    .containerInfoMusic {
        width: calc(100% - 80px);
    }
    .title {
        font-size: 14px;
    }
    .singer {
        font-size: 12px;
        margin: 0px 0 5px 0 !important;
    }
    .play, .more {
        font-size: 12px;
        padding: 1px 6px;
    }
    .playPauseButton {
        height: 80px;
        width: 80px;
    }
    .play, .pause{
        height: 35px;
        width: 35px;
    }
    .play i, .pause i{
        font-size: 16px;
    }
}
@media (max-width: 320px) {  
    .LinkMusic {
        min-width: 280px;
    }
}