.header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
  
.coverWrapper {
    position: relative;
}
  
.cover {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.divBanner {
    position: relative;
    width: 100%;
    height: 130px;
}
@media (max-width: 768px) {  
    .cover {
        height: 250px;
    }
    .divBanner {
        height: 70px;
    }
}
@media (max-width: 500px) {  
    .cover {
        height: 225px;
    }
    .divBanner {
        height: 90px;
    }
}

@media (max-width: 425px) {  
    .cover {
        height: 200px;
    }
    .divBanner {
        height: 70px;
    }
}
