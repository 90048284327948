.Links > div > div > div > div {
    width: 450px;
    height: auto;
}
.Links > div > a {
    width: 100%;
}
@media (max-width: 575px) {  
    .Links > div > div > div > div {
        width: 400px;
    }
}
@media (max-width: 475px) {  
    .Links > div > div > div > div {
        width: 350px;
    }
}
@media (max-width: 425px) {  
    .Links > div > div > div > div {
        width: 320px;
    }
}
@media (max-width: 400px) {  
    .Links > div > div > div > div {
        width: 300px;
    }
}
@media (max-width: 350px) {  
    .Links > div > div > div > div {
        width: 280px;
    }
}