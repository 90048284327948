.titleProfile{
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 0px 0;
}
.Logo{
    margin: 10px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.subtitleProfile{
    font-size: 14px;
    text-align: center;
    margin: 0;
    width: 80%;
}
.imgLogo{
    width: 200px;
    object-fit: cover;
    object-position: center;
    margin: 15px auto 0px auto;
    height: auto;
}